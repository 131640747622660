import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const NewUserRegisterForFamilyTrustEngagement = () => {
  return (
    <Layout>
      <Seo
        title="How Can a New User Register for Family Trust Engagement?"
        description={`This guide will walk you through registering for Family Trust Engagement using the Synkli mobile app.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`new-user-register-for-family-trust-engagement`} />
    </Layout>
  )
}

export default NewUserRegisterForFamilyTrustEngagement
